import "./styles.css"
import logo from '../../images/elas_programam letra trasnparente.png'
/* import github from '../../images/icones/icone_gethub-fill.png' */
import linkedin from '../../images/icones/icone_linkedin-fill.png'
import instagram from '../../images/icones/icone_instagram-fill.png'
import facebook from '../../images/icones/icone_facebook-fill.png'
import carta from '../../images/icones/carta.png'
import cartinha from '../../images/icones/cartinha.png'

function  BarraInferior (props) {

    return (
        
        <div  className="barrainferior">
                <div className="div1-inf">
                    <img className="logo-inf" src= {logo} alt= "logo"></img>
                </div>
                <a className="div2-inf" href="mailto:contato@elasprogramam.com.br">
                    <img className="cartinha" src= {cartinha} alt= "logo"></img>
                    <p className="texto-inf">Quer conversar?</p>
                    <p className="texto-inf">contato@elasprogramam.com.br</p>

                </a>
                <div className="div3-inf">
                    <a href="https://www.instagram.com/elasprogramam/" rel="noopener noreferrer" target="_blank"><img className="but-redesocial" src= {instagram} alt= "logo"></img></a>
                    <a href="https://www.facebook.com/elasprogramam/" rel="noopener noreferrer" target="_blank"><img className="but-redesocial-f" src= {facebook} alt= "logo"></img></a>
                    <a href="https://www.linkedin.com/in/silvia-coelho/" rel="noopener noreferrer" target="_blank"><img className="but-redesocial" src= {linkedin} alt= "logo"></img></a>
                    <a href="mailto:contato@elasprogramam.com.br" rel="noopener noreferrer" target="_blank"><img className="but-redesocial" src= {carta} alt= "logo"></img></a>
                    {/* <a href="https://www.uol.com.br" rel="noopener noreferrer" target="_blank"><img className="but-redesocial" src= {github} alt= "logo"></img></a> */}
                </div>
                    <a className="texto-inf" href="https://www.freepik.com/author/vectorjuice">
                        <p >Images by vectorjuice on Freepik</p>
                        </a>
        </div>

    )
}
export default BarraInferior