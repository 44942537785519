import image1 from '../../images/main box1.png'
import './styles.css'

function SessaoTitulo (props) {


    return (
        <div className="box1" style={{"background":props.backcolor}}>
            {/* <p className="box1-texto">Elas Programam é uma consultoria especializada em desenvolver soluções para empregar mulheres em início de carreira em tecnologia aumentando assim a representatividade feminina neste setor.</p> */}
            <p className="box1-texto">Elas Programam é uma consultoria especializada em desenvolver soluções para engajamento, capacitação e contratação de talentos femininos de tecnologia.</p>
            <img className="box1-image" src= {image1} alt= "logo"></img>
        </div>
    )

}

export default SessaoTitulo