import "./styles.css"
import logo from '../../images/elas_programam letra trasnparente.png'
import hamburguer from '../../images/icones/menu.png'
import xis from '../../images/icones/fechar.png'
import { useEffect, useState } from "react"
function BarraSuperior(props) {

    const [botaoAtivo, setBotaoAtivo] = useState("main")
    const [menuM, setMenuM] = useState(hamburguer)

    useEffect(() => {
        const pages = Object.keys(props.paginas)
        pages.forEach(element => {
            if (!props.paginas[element]) {
                setBotaoAtivo(element)
            }
        });
    }, [props.paginas])

    const mudaPaginaLocal = (param) => {
        props.mudaPagina(param)
        setMenuM(hamburguer)
        setBotaoAtivo(param)
    }
    const alternaMenu = () => {
        menuM === hamburguer ? setMenuM(xis) : setMenuM(hamburguer)
    }

    const listaBotoes = () => {
        const pages = Object.keys(props.paginas)
        var lista = []
        pages.forEach((item, index) => {
            if (props.paginas[item][2]) {
                /* console.log(item)
                console.log(props.paginas[item][2]) */
                var nome = props.paginas[item][1]
                let element = <button key={`kbut${index}`}
                    className={botaoAtivo === item ? "texto-menu-At" : "texto-menu-Inat"}
                    onClick={() => mudaPaginaLocal(item)}>{nome}</button>
                lista.push(element)
            }
        })


        /* const lista = pages.map(function(item, index){
            var nome
            if(props.paginas[item][2]){
                console.log(item)
                console.log(props.paginas[item][2])
                nome = props.paginas[item][1]}
            return <button key={`kbut${index}`}
                className= {botaoAtivo === item? "texto-menu-At":"texto-menu-Inat"}
                onClick={() => mudaPaginaLocal(item)}>{nome}</button>
        }) */
        /* console.log(lista) */
        return lista
    }
    const deslocar = (param) =>
        window.scrollTo({ top: param })

    return (
        <div className="barrasuperior">
            <div className="sup-esquerda">
                <img className="logo-menu" src={logo} alt="logo"></img>
            </div>
            <div className="sup-direita">
                {listaBotoes()}
                {botaoAtivo==="main"?<button
                    className="texto-menu-Inat"
                    onClick={() => deslocar(600)}>Serviços</button>:null}
            </div>
            <div className="sup-direita-mobile">
                <button className="bot-hamburguer" onClick={() => alternaMenu()}>
                    <img src={menuM} alt="logo" />
                </button>
                {menuM === xis && <div className="menuDrop">
                    {/* <p className="texto-menu-tit">Menu</p> */}
                    {listaBotoes()}
                    {botaoAtivo==="main"?<button
                        className="texto-menu-Inat"
                        onClick={() => deslocar(620)}>Serviços</button>:null}
                </div>}
            </div>

        </div>
    )
}
export default BarraSuperior
