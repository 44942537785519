import image5 from '../../images/main box5.png'
import './styles.css'

function SessaoPodcast (props) {


    return (
        <div className="box5" style={{"background":props.backcolor}}>
            <div className='div-box5-esquerda'>
                <p className='p-box5-1'>Elas Programam Podcast</p>
                <p className='p-box5-2'>Elas Programam Podcast conta a história de mulheres anônimas na tecnologia para inspirar meninas e mulheres a quebrar estereótipos e conquistar seu espaço nesse mercado ainda majoritariamente masculino. Porque afinal escrever códigos é contar uma história. E história é substantivo poderoso e feminino.</p>
                <button className='but-box' onClick={() =>props.mudaPagina("podcast")}>Ouça os episódios</button>
            </div>
            <img className="box5-image" src= {image5} alt= "logo"></img>
        </div>
    )

}

export default SessaoPodcast