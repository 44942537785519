import React, { useState, useEffect} from 'react'
import rssparser from 'rss-parser'
import './anchor.css'
/* import { IconContext } from "react-icons";
import { SiAnchor } from "react-icons/si"; */
import Anchoricon from '../../images/podcasts/anchor.png'
import Relog from '../../images/podcasts/relogio.png'

function Anchor (props) {

    
    const [episodes, setEpisodes] = useState([
    <div key="div-carregando"className="div-anchor-container">
        <span>Carregando...</span>
    </div>
        ])

    useEffect(() => {
        GetEpisode()
    },[])

    const GetEpisode = async () => {
        
            const parser = new rssparser()
            const feed = await parser.parseURL("https://anchor.fm/s/14113244/podcast/rss")
            const items = feed.items

            const listaFinal = items.map( function (item) { 
                /* console.log(item) */
                var b= new Date (item.isoDate)
                
                const monthNames = ["jan.", "fev.", "março", "abril", "maio", "junho",  "julho", "agosto", "setembro", "out.", "nov,", "dez."];
                var ano = b.getFullYear()
                var mes = monthNames[b.getMonth()]
                var dia = b.getDate()
                
                return <div className='div-anchor-container' key={item.guid}>
                <div className='div-anchor-esq'>
                    <img alt= 'ok' key='1'
                        src={item.itunes.image}
                        height="96" width ="96">
                    </img>
                    <div className='linha1-anchor'>
                        <img src={Relog} alt="relogio"></img>
                        <p className='p-anchor-data'>{item.itunes.duration}</p>
                    </div>
                    <p className='p-anchor-data'>{`${dia} de ${mes} de ${ano}`}</p>
                </div>
                <div className='div-anchor-dir'>
                    <p className='p-anchor-titulo'>{item.title}</p>
                        {/* <p className='p-anchor-data'>{item.pubDate.slice(5,16)}</p> */}
                    {/* <span className='span-anchor'>{item.contentSnippet.slice(0,75)}<button>...</button></span> */}
                    <a className="a-btn" href={item.link}><img src={Anchoricon} alt="icone anchor"></img></a>
                </div>
            </div>})

        setEpisodes(listaFinal)
    }
    return (
        <div className="div-container-anchor-prin">
            {episodes}
        </div>
    )
}
export default Anchor
    