import { useEffect, useState } from 'react'
import './styles.css'
import api from '../../services/api'
import imagem1 from '../../images/Sandy_Bus-01_Single-10.jpg'
import imagem2 from '../../images/procura-vaga.jpg'
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';



function Vagas() {

    const [verCards, setVerCards] = useState(false)
    const [listaDeVagas, setListaDeVagas] = useState([{
        titulo: "Prestador Junior",
        cidade: "São Paulo",
        regime: "Presencial",
        salario: "1000",
        empresa: "Distribuidora.com",
        createdAt: "2022-09-03T16:57:36.747Z",
        link: "https://www.uol.com.br",
        descricao: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris",
        id: 1
    }])
    useEffect(() => {
        getData()
    }, [])

    const geraCards = () => {
        const listaFinal = listaDeVagas.map(function (item, index) {
            const stringData = item.createdAt.slice(8, 10) + "/" + item.createdAt.slice(5, 7) + "/" + item.createdAt.slice(0, 4)
            /* const stringData = item.data.slice(8, 10) + "/" + item.data.slice(5, 7) + "/" + item.data.slice(0, 4) */
            var salario = "a combinar"
            if (item.salario !== "") {
                salario = "R$ " + parseFloat(item.salario).toFixed(2)
            }
            var stringDescricao
            if (item.descricao.length > 128) {
                stringDescricao = `${item.descricao.slice(0, 128)}...`
            } else {
                stringDescricao = item.descricao
            }
            return <div className='vaga-container' key={`key-vaga${index}`}>
                <p className='card-titulo'>{item.titulo}</p>
                <div className='card-segunda-linha'>
                    <p className='card-texto1'>
                        <FmdGoodIcon sx={{
                            color: "var(--laranjaTexto)",
                            mr: "5px"
                        }} />{item.cidade}</p>
                    <p className='card-texto1'>
                        <MapsHomeWorkIcon sx={{
                            color: "var(--laranjaTexto)",
                            mr: "5px"
                        }} />{item.regime}</p>
                </div>
                <div className='card-terceira-linha'>
                    <p className='card-texto2'>
                        <FiberManualRecordIcon sx={{
                            color: "var(--laranjaTexto)",
                            mr: "5px"
                        }} />{item.empresa}</p>
                    <p className='card-texto2'>
                        <FiberManualRecordIcon sx={{
                            color: "var(--laranjaTexto)",
                            mr: "5px"
                        }} />{`Salário: ${salario}`}</p>
                </div>
                <p className='card-texto3'>{stringDescricao}</p>
                <a href={item.link} rel="noopener noreferrer" target="_blank" className="botao-link">Ver vaga</a>
                <p className='card-texto4'>{`Publicado em ${stringData}`}</p>
            </div>
        })
        return listaFinal
    }

    const getData = async () => {
        var response
        try {
            response = await api.get('/vagas/buscartodas')
        } catch (error) {
            console.log(error)
        }
        /* console.log(response.data) */
        setListaDeVagas(response.data)
        if (response.data.length > 0) setVerCards(true)
    }

    return (
        <div className='vagas-main'>
            <div className="vagas-sessao1">
                <img className="imagem-vagas1" src={imagem1} alt="recrutador"></img>
                <div className='sessa1-box-texto'>
                    <p className='sessao1-titulo'> Oportunidades de Trabalho</p>
                    <p className='sessao1-texto'>Neste espaço vamos divulgar exclusivamente oportunidades em aberto para quem está iniciando na área de tecnologia.</p>
                </div>
            </div>
            <div className="vagas-sessao2">
                {!verCards && <div className='sessa2-box-texto'>
                    <p className='sessao1-texto'>No momento não há vagas para exibir, volte em breve para novidades </p>
                </div>}
                {!verCards && <img className="imagem-vagas1" src={imagem2} alt="recrutador"></img>}
                {verCards && geraCards()}
            </div>


        </div>
    )
}

export default Vagas