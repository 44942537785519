import "./styles.css"

import Parceiras from '../../components/parceiras'
import Apoie from '../../components/apoie'
import Frase from '../../components/frase'

import imageS from '../../images/osbre image.png'
import setaAcima from '../../images/seta acima.png'
import {colaboradoras_major, colaboradoras_minor} from '../../services/colaboradoras'
import linkedin from '../../images/icones/icone_linkedin-fill2.png'

function Sobre (props) {

    const backToTop  = () =>{
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0; 
    }
    const listaColM = () =>{
        const lista = colaboradoras_major.map(function (item,index){
            return <div className ="divcolabM" key={`kdiv${index}`}>
                <img className="img-foto"src={item[3]} alt="foto pessoal"></img>
                <div className="colabLinha1">
                <p>{item[0]}</p>
                <a href={item[2]}><img className="but-linkedin" src= {linkedin} alt= "logo"></img></a>
                </div>
                <p className="colabLinha2">{item[1]}</p>
            </div>
        } )
        return lista
    }
    const listaColC = () =>{
        const lista = colaboradoras_minor.map(function (item,index){
            return <div className ="divcolabM" key={`kdiv${index}`}>
                <div className="colabLinha1">
                <p>{item[0]}</p>
                <a href={item[2]}><img className="but-linkedin" src= {linkedin} alt= "logo"></img></a>
                </div>
                <p className="colabLinha2">{item[1]}</p>
            </div>
        } )
        return lista
    }
    /* console.log(colaboradoras_major[0]) */

    return (
        <div className="main-sobre">
        <div className="sobre-div">
            <div className="sobre-box1">
                <p className="p-sobre-tit-1">Como surgiu a comunidade</p>
                <img className="img-sobre" src={imageS} alt="logo"></img>
            </div>
            <div className="sobre-box2">
            <p className="p-sobre-texto-1">Elas Programam é um movimento criado pela engenheira Silvia Coelho, para aumentar a presença e permanência de mulheres no mercado de tecnologia.</p>
            <p className="p-sobre-tit-2">Temos por objetivo</p>
            <p className="p-sobre-texto-2">Promover autonomia financeira de mulheres que têm ou buscam afinidade com a área de tecnologia;</p>
            <p className="p-sobre-texto-2">Desenvolver aceleração de conhecimento técnico, encurtando tempo de jornada de aprendizado de mulheres iniciantes;</p>
            <p className="p-sobre-texto-2">Conectar áreas de interesse com essas profissionais que estão iniciando essa jornada;</p>
            <p className="p-sobre-texto-2">Tornar as empresas mais diversas e propor equidade de gênero nessa área que no cenário atual é majoritariamente masculina;</p>
            <p className="p-sobre-texto-2">Lembrar que o gap de gênero no setor de tecnologia foi construído por uma sociedade que insiste em invisibilizar o protagonismo das inúmeras mulheres que ajudaram a construir o mundo digital e conectado que conhecemos hoje.</p>
            <p className="p-sobre-tit-2">Como começou</p>
            <p className="p-sobre-texto-1">O grupo foi criado em novembro de 2017 e hoje conta com cerca de 8.000 integrantes. A ideia da criação do grupo surgiu através de uma postagem no facebook de um pedido de ajuda em programação para a  Silva e a partir desse pedido começaram a surgir outras mulheres, contruíndo assim a comunidade Elas Programam para conectar mulheres que querem aprender com as que querem ensinar, construindo conexões, compartilhando conteúdo e experiências.</p>
            <button className="link-topo-mobile" onClick={()=>backToTop()}>
                <span>Voltar ao Topo</span>
                <img className="seta-acima" src={setaAcima} alt="voltar ao topo"></img>
                </button>
            </div>
            <div className="sobre-box3">
                <p className="p-sobre-tit-1">Colaboradoras</p>
                <div className="colabMFlex">
                    {listaColM()}
                </div>
                <p className="p-sobre-tit-1">Pessoas que já contribuíram para a comunidade</p>
                <div className="colabCFlex">
                    {listaColC()}
                </div>
                <button className="link-topo-mobile" onClick={()=>backToTop()}>
                <span>Voltar ao Topo</span>
                <img className="seta-acima" src={setaAcima} alt="voltar ao topo"></img>
                </button>
            </div>
        </div>
            <Parceiras/>
            <Apoie/>
            <Frase/>
        </div>
    )
}

export default Sobre