import './styles.css'


function SessaoProps (props) {

    const acao = () =>{
        props.acao()
    }

    return (
        <div className="sessaoPropAm" style={{"background":props.backcolor}}>
                <div className="sesPA-info">
                    <p className='tituloSA'>{props.titulo}</p>
                    <p className='textoSA'>{props.texto}</p>
                    <p className='textoSA'>{props.texto1}</p>
                    <p className='textoSA'>{props.texto2}</p>
                    <button className='but-box' onClick={acao}>Saiba mais</button>
                </div>
                <img className="imageSA" src= {props.imagem} alt= "logo"></img>
            </div>
    )
}

export default SessaoProps
