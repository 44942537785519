import "./styles.css"

import Parceiras from '../../components/parceiras'

import pythonImg from '../../images/python.png'
import workshopImg from '../../images/workshop.png'
import planejImg from '../../images/planejamento.png'
import Relog from '../../images/podcasts/relogio.png'

function Python(props) {

    const _hRef = "https://forms.gle/MzYp6JAaPkjbikiB9"
    //const _hRef = "https://www.sympla.com.br/evento-online/curso-basico-de-logica-de-programacao-com-python-de-mulheres-para-mulheres-saindo-do-zero/1822810"

    const _buttonText = "Entre na lista de espera"
    //const _buttonText = "Clique aqui para se inscrever"

    const _buttonText2 = "Lista de espera"
    //const _buttonText2 = "Inscreva-se"

    const verDatas = false

    return (
        <div className="main-python">
            <div className="python-titulo">
                <div className="titulo-info">
                    {/* <p>Curso Introdução Front-End Saindo do Zero: Exclusivo para Mulheres</p> */}
                    <p>Curso Básico de Lógica de Programação com Python de Mulheres para Mulheres: Saindo do Zero</p>
                    <div className='linha2-tit'>
                        {/* <img src={Relog} alt="imagem de relógio"></img> */}
                        {/* <p className='p-anchor-data'>29 de novembro a 8 de dezembro de 2022</p> */}
                        {/* <p className='p-anchor-data'>17/01 a 26/01/2023</p> */}
                        {/* <p className='p-anchor-data'>Garantimos um ambiente seguro e confortável para que mulheres possam escrever suas primeiras linhas de código.</p> */}
                    </div>
                    <div className='linha2-tit'>
                        <p className='p-anchor-data'>Nosso curso é exclusivo para mulheres iniciantes, então não precisa de conhecimento prévio, apenas comprometimento e vontade de aprender</p>
                    </div>
                    <a className="but-box" href={_hRef}>
                        {_buttonText}</a>
                    {/* <span className='p-encerradas'>Inscrições Encerradas</span> */}
                </div>
                <img className="python-image" src={pythonImg} alt="logo"></img>
            </div>
            <div className="python-descr">
                <img className="python-image" src={workshopImg} alt="logo"></img>
                <div className="descr-info">
                    <p className="descr-texto">Atendendo a muitos pedidos, Elas Programam e Quero Ser Programadora se uniram para lançar mais uma turma do curso básico de lógica de programação com Python, exclusivo para mulheres. Esse curso é para mulheres que desejam aprender a programar e precisam de ajuda nos primeiros passos.</p>
                    <p className="descr-texto">- Se você já tentou aprender sozinha e sentiu muitas dificuldades, esse curso é para você.</p>
                    <p className="descr-texto">- Se você prefere aprender o bê-a-bá com outras mulheres para se sentir segura e confortável para perguntar, errar até acertar, esse curso é para você.</p>
                    <p className="descr-texto">- Se você já sabe programar e já tem conhecimentos em lógica de programação e Python então esse curso NÃO é para você. </p>
                    <p className="descr-texto">Venha aprender lógica de programação em curso feito por mulheres para mulheres!</p>
                    {/* <p className="descr-texto">Atendendo a muitos pedidos, Elas Programam e Quero Ser Programadora se uniram para lançar o Curso Introdução ao Front-End Saindo do Zero: Exclusivo para Mulheres.</p>
                    <p className="descr-texto">- Vamos pegar na sua mão e guiar seus primeiros passos no mundo do Front-End apresentando os conceitos básicos de HTML, CSS e JavaScript.</p>
                    <p className="descr-texto">- Se você já tentou aprender sozinha e sentiu muitas dificuldades, esse curso é para você.</p>
                    <p className="descr-texto">- Se você prefere aprender o bê-a-bá com outras mulheres para se sentir segura e confortável para perguntar, errar até acertar, esse curso é para você.</p>
                    <p className="descr-texto">- Se você já tem conhecimentos em HTML, CSS e JavaScript então esse curso NÃO é para você.</p>
                    <p className="descr-texto">Comece a desbravar o mundo do Front-End nesse curso feito por mulheres para mulheres! Criamos esse curso básico com muito carinho e dedicação para que você não se sinta mais tão perdida. Vamos juntas!!</p> */}
                </div>
            </div>
            <div className="python-porque">
                <p className="porque-tit">Por que Python?</p>
                <p className="descr-texto">Porque é uma linguagem simples, acessível e fácil de usar: perfeita para quem está saindo do zero na programação e não sabe por onde começar! Não à toa, Python é a principal escolha para soluções pedagógicas que visam ensinar programação.</p>
                <p className="porque-tit">Para quem?</p>
                <p className="descr-texto">Mulheres interessadas em aprender a programar, que se sentem inseguras para aprender sozinhas! A idade mínima para participar do curso é 18 anos</p>
                <p className="porque-tit">Como vai funcionar?</p>
                <p className="descr-texto">Serão 06 encontros de aproximadamente 2 horas de Oficina Online (ao Vivo) com Aline Teles, incluindo suporte e acompanhamento via whatsapp enquanto durar o curso. A plataforma que vamos usar é o Zoom!</p>
                <p className="descr-texto">Vamos fazer exercícios de forma prática em simuladores online para você não ficar baixando milhares de programas. Simples, rápido e direto!</p>
                {/* <p className="porque-tit">Para quem?</p>
                <p className="descr-texto">Mulheres interessadas em trilhar os primeiros passos em Desenvolvimento Front-End e que se sentem inseguras para aprender sozinhas!</p>
            <p className="porque-tit">Como vai funcionar?</p>
                <p className="descr-texto">Serão 04 encontros online e ao vivo com Aline Teles, incluindo suporte e acompanhamento via whatsapp enquanto durar o curso. A plataforma que vamos usar é o Zoom!</p>
                <p className="descr-texto">Vamos fazer exercícios de forma prática ..... Simples, rápido e direto!</p> */}
                <a className="but-box" href={_hRef}>{_buttonText}</a>
            </div>
            <div className="python-aviso">
                <p className="aviso-texto">Atenção: As aulas serão gravadas portanto será possível rever o conteúdo. Vamos disponibilizar um material em pdf de cada aula e sempre faremos uma revisão do conteúdo do dia anterior no início dos nossos encontros.</p>
                <img className="python-image2" src={planejImg} alt="logo"></img>
            </div>
            <div className="python-info">
                <p className="porque-tit">Pré-requisitos?</p>
                <p className="descr-texto">Nenhum conhecimento prévio é necessário para participar desse curso. Basta muita vontade de aprender!</p>
                <p className="porque-tit">Material necessário?</p>
                <p className="descr-texto">Computador e acesso à internet para acompanhar as atividades que são totalmente práticas.</p>
                {verDatas?<p className="porque-tit">Quando?</p>:null}
                {verDatas?<p className="descr-texto">de 29/11 a 08/12 | Terças, Quartas e Quintas das 19 às 21:00h</p>:null}
                {verDatas?<p className="porque-tit">Datas das Aulas?</p>:null}
                {verDatas?<p className="descr-texto">29 e 30 de novembro - 01, 06, 07 e 08 de dezembro</p>:null}
                <p className="porque-tit">Carga horária</p>
                <p className="descr-texto">12 horas de aula online</p>
                <p className="descr-texto">30 dias de acompanhamento via whatsapp (a contar do primeiro dia de aula) </p>
            </div>
            <div className="python-conteudo">
                <p className="porque-tit">Conteúdo a ser abordado</p>
                {/* <p className="descr-texto">- Conceitos gerais sobre sistemas e internet</p>
                    <p className="descr-texto">- HTML: o que é, estrutura básica, tags, separação entre conteúdo e informações, navegação, lista</p>
                    <p className="descr-texto">- CSS: o que é, estilo em cascata, manipulações de imagens, blocos e divisões semânticas</p>
                    <p className="descr-texto">- Introdução a JavaScript</p> */}
                <div className="conteudo-flex">
                    <p className="conteudo-texto">1. Conceitos</p>
                    <p className="conteudo-texto">2. Algoritmo</p>
                    <p className="conteudo-texto">3. Variáveis</p>
                    <p className="conteudo-texto">4. Interação com usuários </p>
                    <p className="conteudo-texto">5. Tipagem de dados</p>
                    <p className="conteudo-texto">6. Operadores Lógicos e aritméticos</p>
                    <p className="conteudo-texto">7. Precedência de operadores</p>
                    <p className="conteudo-texto">8. Estrutura sequencial</p>
                    <p className="conteudo-texto">9. Estrutura condicional simples</p>
                    <p className="conteudo-texto">10. Estrutura condicional composta</p>
                    <p className="conteudo-texto">11. Listas</p>
                </div>
                <p className="porque-tit">Tem certificado?</p>
                <p className="descr-texto">Sim, emitimos certificado de participação.</p>
            </div>
            <div className="python-link">
                <p className="link-texto">Criamos esse curso básico com muito carinho e dedicação para que você não se sinta mais tão perdida no mundo da programação. Vamos juntas!!</p>
                <a className="but-box" href={_hRef}>{_buttonText2}</a>

            </div>
            <div className="python-cancelamento">
                <p className="porque-tit">Política de cancelamento de pedidos:</p>
                <p className="descr-texto">Pedidos podem ser cancelados até 7 (sete) dias após a sua compra, desde que a solicitação seja realizada 48h antes do início das aulas. </p>

            </div>
            <Parceiras />

        </div>
    )
}
export default Python