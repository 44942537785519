import './styles.css'
import image4 from '../../images/main box4.png'

function Apoie (props) {
    return (
<div className="box4">
            <img className="box4-image" src= {image4} alt= "logo"></img>
            <div className='div-box4-esquerda'>
                <p className='p-box4-1'>Apoie a comunidade</p>
                <p className='p-box4-2'>Ajude a comunidade a impulsionar mulheres na carreira de tecnologia.</p>
                <button className='but-box4'>Saiba mais</button>
            </div>
        </div>
    )
}
export default Apoie