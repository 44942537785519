import "./styles.css"
import {comunidades} from '../../services/colaboradoras'

function Parceiras (props) {

    const geraLista =()=> {
        const lista = comunidades.map(function(item,index){
            return(
                <a key={`key-com-${index}`} href = {item[0]}>
                    <img className="link-parc" src={item[1]} alt ='imagem comunidades'></img>
                </a>)
        })
        return lista
    }

    return (
        <div className = "div-parc-main">
            <p className = "p-titulo-parc">Empresas Parceiras</p>
            <div className="box-flex-parc">
                {geraLista()}
            </div>
        </div>
    )
}
export default Parceiras