import './styles.css'
import pythonImg from '../../images/python.png'
/* import Apoie from '../../components/apoie' */
/* import SessaoMentoria from '../../components/sessaoMentoria' */
import Frase from '../../components/frase'
import SessaoProps from '../../components/sessaoPropAmarela'
import SessaoTitulo from '../../components/sessaoTitulo'
import SessaoDados from '../../components/sessaoDados'
import SessaoPodcast from '../../components/sessaoPodcast'
import SessaoTemplate from '../../components/sessaoTemplate'
/* import SessaoExtra from '../../components/sessaoExtra' */

function Main (props) {


return (
    <div className="main-box">
        <SessaoTitulo backcolor ="var(--fundoBoxClaro)"/>
        <SessaoDados backcolor ="var(--fundoBoxCreme)"/>
        <SessaoProps
            flexdirection="row-reverse"
            backcolor ="var(--fundoQuaseBranco)"
            acao = {() => props.mudaPagina("python")}
            imagem ={pythonImg}
            titulo = "Curso Básico de Lógica de Programação com Python de Mulheres para Mulheres: Saindo do Zero"
            texto = "Nosso curso é online e ao vivo. As aulas são gravadas e ficam disponíveis por 30 dias após o curso."
        />
        {false && <SessaoProps
            flexdirection="row-reverse"
            backcolor ="var(--fundoQuaseBranco)"
            acao = {() => props.mudaPagina("python")}
            /* acao = {() => window.location.href = "https://forms.gle/MzYp6JAaPkjbikiB9"} */
            /* acao = {() => window.location.href = "https://www.sympla.com.br/evento/frontin-elas-programam-2022-presencial/1499433"} */
            imagem ={pythonImg}
            titulo = "Curso Básico de Lógica de Programação com Python de Mulheres para Mulheres: Saindo do Zero"
            texto = "Nosso curso é online e ao vivo. As aulas são gravadas e ficam disponíveis por 30 dias após o curso."
            texto2 ="De 16/08/2022 a 24/08/2022"
            /* titulo = "Evento exclusivo para mulheres na tecnologia, parceria entre Elas Programam e FRONTIN."
            texto = "SÁBADO 30 DE ABRIL, 2022 |  10 ÀS 17H, TEATRO GAZETA" */
            /* texto1 = "AV.PAULISTA, 900 SÃO PAULO"
            texto2 = "INGRESSOS NO SYMPLA - PRESENCIAL" */
        />}
        {/* <SessaoExtra backcolor= "var(--fundoBoxCreme)"/> */}
        <SessaoPodcast backcolor="var(--fundoBoxCreme)" mudaPagina={props.mudaPagina}/>
        <SessaoTemplate backcolor="var(--fundoBoxClaro)" mudaPagina={props.mudaPagina}/>
        {/* <SessaoMentoria backcolor="var(--fundoBoxClaro)" mudaPagina={props.mudaPagina}/> */}
        
        {/* <Apoie/> */}
        
        <Frase/>
        
    </div>
)
}
export default Main