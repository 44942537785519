import image3 from '../../images/main box3.png'
import './styles.css'

function SessaoTemplate (props) {


    return (
        <div className="boxT" style={{"background":props.backcolor}}>
            <div className='div-boxT-esquerda'>
                <p className='p-boxT'>Campanha</p>
                <p className='p-boxT'> #contrateumaDevaJr</p>
                {/* <p className='p-box3-2'>Campanha #ContrateUmaDevaJr. Use o nosso template para fortalecer o movimento nas redes.</p> */}
                <button className='but-box' onClick={() =>props.mudaPagina("template")}>Saiba mais</button>
            </div>
            <img className="box3-image" src= {image3} alt= "logo"></img>
        </div>        
    )

}

export default SessaoTemplate