import fotoSilvia from '../images/silvia1.png'
import fotoMaria from '../images/silvia2.png'
import fotoCarla from '../images/silvia3.png'
import  elas from '../images/apoio-elas.png'
/* import  amazon from '../images/amazon.jpg'
import  elon from '../images/elon.jpg' */
import quero from '../images/quero.png'

export const colaboradoras_major =[
    ["Silvia Coelho", "Fundadora","https:\\linkedin.com/in/silvia-coelho/",fotoSilvia],
    ["Maria Coelho", "Produção podcast","https:\\linkedin.com/in/silvia-coelho/",fotoMaria],
    ["Carla Coelho", "Comunicação","https:\\linkedin.com/in/silvia-coelho/",fotoCarla]
]
export const colaboradoras_minor =[
    ["Marina Azevedo", "Ocupação","https:\\linkedin.com/in/silvia-coelho/"],
    ["Rebeca Arantes", "Ocupação","https:\\linkedin.com/in/silvia-coelho/"],
    ["Amanda Silva", "Ocupação","https:\\linkedin.com/in/silvia-coelho/"],
    ["Nathalia Monalisa", "Ocupação","https:\\linkedin.com/in/silvia-coelho/"],
    ["Greyce Riquinho", "Ocupação","https:\\linkedin.com/in/silvia-coelho/"],
    ["Danielle Clericuzzi", "Ocupação","https:\\linkedin.com/in/silvia-coelho/"],
    ["Daniela Karasawa", "Ocupação","https:\\linkedin.com/in/silvia-coelho/"],
    ["Roberta Perdigão", "Ocupação","https:\\linkedin.com/in/silvia-coelho/"],
    ["Michaela Dafne", "Ocupação","https:\\linkedin.com/in/silvia-coelho/"],
]
//tamanho ideal da imagem w257 h248
export const comunidades =[
    ["https://www.instagram.com/elasprogramam/",elas],
    ["https://www.instagram.com/queroserprogramadora/",quero],
    /* ["https:\\google.com",elon] */
]

