import './styles.css'
import image2 from '../../images/main box2.png'
import EmailIcon from '@mui/icons-material/Email';
function SessaoDados (props) {

    return (
        <div className="box2" style={{"background":props.backcolor}}>
        <div className='box2-d'>
            <img className="box2-image" src= {image2} alt= "logo"></img>
            <div className='div-box2-direita'>
                <p className='p-box2-1'>Elas Programam foi criada pela engenheira Silvia Coelho com o objetivo de inspirar, conectar e gerar oportunidades profissionais para aumentar a participação de mulheres na área de tecnologia.</p>
                <p className='p-box2-1'>Formatos de parcerias com empresas:</p>
                <p className='p-box2-1'>1 - Hunting de talentos femininos de tech</p>
                <p className='p-box2-1'>2 - Campanhas personalizadas de fortalecimento de marca empregadora</p>
                <p className='p-box2-1'>3 - Programas personalizados de formação técnica</p>
                <p className='p-box2-1'>Para contratar nossos serviços, favor entrar em contato através do e-mail <a
                     href="mailto:contato@elasprogramam.com.br" rel="noopener noreferrer" target="_blank">contato@elasprogramam.com.br
                    </a>
                </p>
                {/* <p className='p-box2-3'>+ 90 mil pessoas nos acompanham em diversas redes sociais, o que nos ajuda a divulgar e fortalecer a marca das empresas parceiras de forma orgânica e consistente.</p>
                <p className='p-box2-1'>Temos mais de 30 mil seguidores no nosso instagram @elasprogramam onde compartilhamos conteúdos técnicos, dicas de carreira em tecnologia, eventos e oportunidades.</p> */}
                <a className='but-box' href="mailto:contato@elasprogramam.com.br" rel="noopener noreferrer" target="_blank">Contato <EmailIcon/></a>
                {/* <a className='but-box' href='https://www.instagram.com/elasprogramam/'>Contato <EmailIcon/></a> */}
            {/*     <p className='p-box2-1'>Participe da nossa comunidade no Facebook</p>
                <a className='but-box' href="https://www.facebook.com/elasprogramam">Quero participar</a> */}
            </div>
        </div>
        <div className='box2-m'>
                <p className='p-box2-1'>Elas Programam foi criada pela engenheira Silvia Coelho com o objetivo de inspirar, conectar e gerar oportunidades profissionais para aumentar a participação de mulheres na área de tecnologia.</p>
                <img className="box2-image" src= {image2} alt= "logo"></img>
                {/* <p className='p-box2-3'>+ 90 mil pessoas nos acompanham em diversas redes sociais</p> */}
                <p className='p-box2-1'>Nossa atuação se divide em três áreas:</p>
                <p className='p-box2-1'>1 - Hunting de talentos femininos de tech</p>
                <p className='p-box2-1'>2 - Campanhas personalizadas de fortalecimento de marca empregadora</p>
                <p className='p-box2-1'>3 - Programas de formação em desenvolvimento front-end, back-end e análise de dados com possibilidade de contratação no final</p>
                <p className='p-box2-1'>Para contratar nossos serviços, favor entrar em contato através do e-mail <a
                    href="mailto:contato@elasprogramam.com.br" rel="noopener noreferrer" target="_blank">contato@elasprogramam.com.br
                    </a>
                </p>
                {/* <p className='p-box2-1'>Temos mais de 30 mil seguidores no nosso instagram @elasprogramam onde compartilhamos conteúdos técnicos, dicas de carreira em tecnologia, eventos e oportunidades.</p> */}
                <a className='but-box' href="mailto:contato@elasprogramam.com.br" rel="noopener noreferrer" target="_blank">Contato <EmailIcon/></a>
                {/* <a className='but-box' href='https://www.instagram.com/elasprogramam/'>Contato <EmailIcon/></a> */}
                {/* <p className='p-box2-1'>Participe da nossa comunidade no Facebook</p>
                <a className='but-box' href='https://www.instagram.com/elasprogramam/'>Quero participar</a> */}
        </div>
        
    </div>
    )

}

export default SessaoDados