import image9 from '../../images/wavy.jpg'
import './styles.css'

function SessaoPagseguro (props) {


    return (
        <div className="boxPag" style={{"background":props.backcolor}}>
            <img className="boxPag-image" src= {image9} alt= "logo"></img>
            <div className='div-boxPag-esquerda'>
                <p className='p-boxPag-1'>Faça sua inscrição aqui</p>
                <p className='p-boxPag-2'>Elas Programam Podcast conta a história de mulheres anônimas na tecnologia para inspirar meninas e mulheres a quebrar estereótipos e conquistar seu espaço nesse mercado ainda majoritariamente masculino. Porque afinal escrever códigos é contar uma história. E história é substantivo poderoso e feminino.</p>
                {/* <button className='but-box' onClick={() =>props.mudaPagina("podcast")}>Ouça os episódios</button> */}
                {/* <!-- INICIO DO BOTAO PAGSEGURO --> */}
                <a href="https://pag.ae/7XWp1MG-r/button" target="_blank" rel="noreferrer" title="Pagar com PagSeguro"><img src="//assets.pagseguro.com.br/ps-integration-assets/botoes/pagamentos/205x30-pagar-laranja.gif" alt="Pague com PagSeguro - é rápido, grátis e seguro!" /></a>
                {/* <!-- FIM DO BOTAO PAGSEGURO --> */}
            </div>
        </div>
    )

}

export default SessaoPagseguro