import "./styles.css"
import "../../services/squareCheck.css"
import { useRef, useState} from 'react'
import api from "../../services/api.js"
import ReCAPTCHA from 'react-google-recaptcha'
/* import Parceiras from '../../components/parceiras' */
import CheckBox from '../../components/checkbox'
import imageT from '../../images/post_03mar.png'
import Spinner from "../../components/spinner"

const {REACT_APP_RECAPTCH_SITE_KEY} = process.env

function Template (props) {
    const [campos, setCampos] = useState({
        nome: '',
        email: '',
        concordo: false,
        linkedin:'',
        fonte: 'template_dev_junior_03_22',
        token:""
    });

    const [mensagem,setMensagem] = useState("")
    const [verMensagem,setVerMensagem] = useState(false)
    const [verBot_Spinner, setVerBot_Spinner] = useState(true)

    const reRef = useRef();

    function handleInputChange (event) {
        setVerMensagem(false)
        setCampos({...campos,[event.target.name]:event.target.value});
        
        /* if(event.target.name ==="concordo") {
            setCampos({...campos, concordo:!campos.concordo})
            setVerMensagem(false)
        } else {
            setCampos({...campos,[event.target.name]:event.target.value});
        } */
    }
    const setConcordo = () =>{
        setCampos({...campos, concordo:!campos.concordo})
        setVerMensagem(false)
    }
    function handleFormSubmit (event) {
        /* console.log("submit") */
       event.preventDefault();
       if (!campos.concordo) {
        setMensagem("Por favor, concorde com as condições!")
        setVerMensagem(true)
        return
        }
        /* console.log("ops") */
        sendData(campos)

        /* setMensagem("De acordo")
        setVerMensagem(true) */
    }
    
    const sendData = async (data) => {
        const token = await reRef.current.executeAsync()
        campos.token = token
        setCampos(campos)
        reRef.current.reset()
        if(token === ""){
            setMensagem("Ops! Aldo deu errado com o Recaptcha!")
            setVerMensagem(true)
            return
        }
        const dataSend = {...data,
        templateId:"3z0vklo67z1l7qrx",
        link: "http://www.uol.com.br",
        setFrom: "contato@elasprogramam.com.br",
	    setSubject:"Seu template #contrateumaDevaJr",
	    setFromName:"Silvia Coelho"}
        /* console.log(dataSend)
        
        return */
        
        try {
            setVerBot_Spinner(false)
            const response = await api.post("/mail/sendMail", dataSend)
            console.log(response)
            if (response.data.resultado ===202) {
                setMensagem(`Link do template enviado com sucesso para ${data.email}`)
                clearForm()
            } else if (response.data.resultado ==="erro-recaptcha") {
                setMensagem("Humm..você não parece ser humano!")
            } else {
                setMensagem("Ops! Por favor tente novamente!")
            }
        } catch (error) {
            setMensagem("Ops! Por favor, tente novamente!")            
        }
        setVerMensagem(true)
        setVerBot_Spinner(true)
    }
    const clearForm = () =>{
        document.getElementById("input-nome").value=""
        document.getElementById("input-email").value=""
        document.getElementById("input-linkedin").value=""
    }
    return (
        <div>
        <div className="boxTemp">
            <div className='div-boxTemp-esquerda'>
                <p className='p-boxTemp-1'>Campanha #contrateumaDevaJr</p>
                <p className='p-boxTemp-2'>Está de volta a campanha #contrateumaDevaJr que tem o objetivo de conectar desenvolvedoras Jr com empresas contratantes!</p>
                <p className='p-boxTemp-2'>Para participar, basta usar o nosso template lindo lindo maravilhoso e postar no seu perfil do LinkedIn. Para aumentar a visualização do seu post, use as hashtags #contrateumaDevaJr #elasprogramam e marque Elas Programam e Silvia Coelho.</p>
            </div>
            <img className="boxTemp-image" src= {imageT} alt= "logo"></img>
        </div>
        <div className="template-main">
            <div className="template-esq">
                    <ReCAPTCHA sitekey={REACT_APP_RECAPTCH_SITE_KEY} size="invisible" ref={reRef} />
                        <p className="tituloForm"> Receba no seu e-mail o template da campanha</p>
                    <form className ="form-main" onSubmit={handleFormSubmit} >
                        <label>Nome Completo </label>
                        <input id="input-nome" type="text" placeholder="Nome Completo" name="nome" onChange={handleInputChange}required></input>
                        <label>E-mail</label>
                        <input id="input-email" type="email"placeholder="user@email.com" name="email" onChange={handleInputChange} required></input>
                        <label>Perfil no Linkedin</label>
                        <input id="input-linkedin" type="text" placeholder="Link do seu perfil no Linkedin"name="linkedin" onChange={handleInputChange} required></input>
                        <CheckBox id="ckeckBox"
                            acao = {setConcordo}
                            mensagem = "Concordo em receber comunicação da Elas Programam"
                            />

                        {verBot_Spinner?<button className='but-box' type="submit" value="enviar" >Enviar</button>:
                        <Spinner
                            classname= "-finalizar"
                            mensagem="Aguarde"/>}
                        {verMensagem? <p className="p-messageArea">{mensagem}</p>:<p className="p-messageAreaVazia"></p>}
                    </form>
                </div>  
            <div className="template-dir">
                <p className='p-tempTit'>Como baixar seu template</p>
                <p className='p-temp1'>Para baixar o seu template, preencha o formulário. Enviaremos o link do template por email para você!
</p>
                <p className='p-temp1'>Junte-se a nós na campanha #contrateumaDevaJr e ajude as empresas a te acharem.</p>
            </div> 
        </div>
        </div>
    )
}

export default Template