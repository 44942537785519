import "./styles.css"

import image6 from '../../images/silvia2.jpg'
/* import pythonImg from '../../images/silvia2.jpg'
import SessaoProps from '../../components/sessaoPropAmarela' */
import SessaoPagseguro from "../../components/sessaoPagseguro"

function Mentoria(props) {
    return (
        <div>
            <div className="main-mentoria">
                <div className='mentoria-texto'>
                    <p className='p-box3-1'>Mentoria de Carreira</p>
                    <p className='p-mentoria-2'>A mentoria de carreira é indicada para mulheres que desejam iniciar uma carreira em tecnologia
                        de forma organizada e bem informada, recebendo orientações de alto valor e conteúdo exclusivo da engenheira paraense Silvia Coelho -
                        Top Voice LinkedIn 2022, palestrante e mentora experiente que tem muito conhecimento para compartilhar e é reconhecida
                        como referência e forte ativista pela inclusão de mulheres na TI.</p>
                    <p className='p-mentoria-2'>Em breve, abriremos inscrições para a próxima turma. Inscreva-se na lista de espera para saber de tudo em primeira mão. </p>
                    <a className="but-box" href="https://forms.gle/q9wn2K8HqTekUaPK6">Lista de espera</a>
                    {/* <a className="but-box" >Saiba mais</a> */}
                    {/* <a className="but-box" href="https://pag.ae/7XWp1MG-r/button" target="_blank" title="Pagar com PagSeguro">Pagar com Pagseguro</a> */}
                </div>
                <img className="mentoria-image" src={image6} alt="logo"></img>
            </div>
            <SessaoPagseguro backcolor ="var(--fundoBoxCreme)"/>
        </div>


    )
}
export default Mentoria
