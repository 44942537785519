import { useState } from "react"
import "./styles.css"

import marked from '../../images/icones/checkbox-marked.png'
import unmarked from '../../images/icones/checkbox-unmarked.png'

function CheckBox (props) {
    const clicar  = () =>{
        props.acao()
        setChecked(!checked)
    }

    const [checked,setChecked] = useState(false)
    return (
        <button type="button" className="checkbox-main" onClick={()=>clicar()}>
            {checked?  <img className="check-img" src={marked} alt="desmarcado"></img>:
            <img className="check-img" src={unmarked} alt="marcado"></img>}
            <p>{props.mensagem}</p>
        </button>
    )
}

export default CheckBox