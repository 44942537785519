import './styles.css'

function Frase (props) {
    return (
        <div className="box6">
            <div className='div-box6'>
                <p className='p-box-texto'>“Tecnologia é coisa de mulher, sim!”</p>
                {/* <p className='p-box-texto'>by Dan.</p> */}
            </div>
        </div>
    )
}
export default Frase