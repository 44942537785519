/* import logo from './logo.svg'; */
import Mentoria from './pages/mentoria';
import Sobre from './pages/sobre';

import './App.css';
import BarraSuperior from './components/barrasuperior';
import BarraInferior from './components/barrainferior';

import Main from "./pages/main"
import Podcasts from './pages/podcasts';
import Python from './pages/python';
import Template from './pages/template';
import Vagas from './pages/vagas';

import api from './services/api';

import { useEffect, useState } from 'react';
function App() {
    
    /*  const paginas é:
        [controle de exibição da página,
        Nome no Menu,
        controle de exibição no menu] */
    const [paginas,setPaginas] = useState({
        main:[false,"Home",true],
        template:[true,"Template",false],
        podcast:[true,"Podcast",false],
        python:[true,"Curso",false],
        vagas:[true,"Oportunidades",false],
        sobre:[true,"Sobre",false],
        mentor:[true,"Mentoria",false],
    })
    /* const [paginas,setPaginas] = useState({
        main:[false,"Home",true],
        template:[true,"Template",false],
        podcast:[true,"Podcast",false],
        python:[true,"Curso",false],
        vagas:[true,"Oportunidades",false],
        sobre:[true,"Sobre",false],
        mentor:[true,"Mentoria",false],
    }) */
    /* const {REACT_APP_teste} = process.env
    console.log("teste: "+ REACT_APP_teste)
    console.log(process.env) */
 
    useEffect(()=>{
        getPaginas()
    },[])
    const getPaginas = async () =>{
        var lista
        try {
            lista = await api.get('/paginas/buscar')
            var newPaginas = {}
            /* console.log(lista.data) */
            lista.data.forEach(element => {
                const item = {
                    [element.pagina[0]]:[
                    element.pagina[1],
                    element.pagina[2],
                    element.pagina[3],
                ]}
                newPaginas={...newPaginas,...item}
                
            });
            /* console.log(newPaginas) */
            setPaginas(newPaginas)
                        /* lista.data.forEach(element => {
                newPaginas={...newPaginas,...element.pagina}
            });
            console.log(newPaginas)
            setPaginas(newPaginas) */
        } catch (error) {
            console.log(error)
        }
    }
    
    const mudaPagina = (param)=> {
        var paginaNova = {...paginas}
        for (const key in paginaNova) {
            if (Object.hasOwnProperty.call(paginaNova, key)) {
                if(key === param) {
                    paginaNova[key][0] = false
                } else {
                    paginaNova[key][0] = true
                }
            }
        }
        setPaginas(paginaNova)
    }
  return (
    <div className="App">
    <BarraSuperior
    mudaPagina={mudaPagina}
    paginas={paginas}
    />
    {!paginas.main[0] && <Main mudaPagina={mudaPagina}/>}
    {!paginas.mentor[0] && <Mentoria/>}
    {!paginas.podcast[0] && <Podcasts/>}
    {!paginas.template[0] && <Template/>}
    {!paginas.python[0] && <Python/>}
    {!paginas.vagas[0] && <Vagas/>}
    {!paginas.sobre[0] && <Sobre/>}
    <BarraInferior/>
</div>
  );
}

export default App;
