/* import {useEffect} from 'react' */
import './styles.css'
import Anchor from '../../components/anchor'
/* import Apoie from '../../components/apoie' */
import Parceiras from '../../components/parceiras'
import Frase from '../../components/frase'
import ImagemPod from '../../images/podcasts/podcastprin.png'

import radiopublic from '../../images/podcasts/radiopublic.png'
import googlepodcast from '../../images/podcasts/google podcast.png'
import spotify from '../../images/podcasts/Spotify_icon-icons.png'
import breaker from '../../images/podcasts/breaker.png'
import pocket from '../../images/podcasts/pocketcast.svg'

function Podcasts (props) {


return (
    <div className="main-box-pod">
        <div className="pod-box1">
            <div className='pod-box1-a'>
            <p className='p-box1-tit'>Elas programam Podcast</p>
            <img src={ImagemPod} alt="foto"></img>
            </div>
            <p className='p-box1-texto'>Elas Programam Podcast nasceu para ampliar o debate sobre a inserção de mulheres no mercado de tecnologia e atingir mais pessoas. Vou ajudar a contar histórias de mulheres que atuam no mercado de tecnologia, para dar voz a quem não teve ainda a oportunidade de falar sobre sua "Jornada da Heroína". É sobre sucessos, fracassos e retomadas de mulheres anônimas (ou quase), que estão estudando, em transição de carreira ou já chegaram na tão sonhada vaga no mercado de TI aqui no Brasil ou lá fora. Venham ouvir e se inspirar! Links das plataformas disponíveis nos comentários.</p>
            <p className='p-box1-texto'>Elas Programam Podcast contacom histórias de mulheres anônimas na tecnologia para inspirar meninas e mulheres a quebrar estereótipos e conquistar seu espaço nesse mercado ainda majoritariamente masculino. Porque afinal escrever código é criar uma história. E história é substantivo poderoso e feminino.</p>
        </div>
        <div className="pod-box2">
            <p className='p-box1-tit'>Onde escutar</p>
            <div className='pod-box2-a'>
                <a href="https://radiopublic.com/elas-programam-8XlMlV" rel="noopener noreferrer" target="_blank"><img className='pod-image' src={radiopublic} alt ="radiopublic"></img></a>
                <a href="https://www.google.com/podcasts?feed=aHR0cHM6Ly9hbmNob3IuZm0vcy8xNDExMzI0NC9wb2RjYXN0L3Jzcw==" rel="noopener noreferrer" target="_blank"><img className='pod-image' src={googlepodcast} alt ="googlepodcast"></img></a>
                <a href="https://open.spotify.com/show/1Ay5y5TFWQPq3Pw2Xy209U" rel="noopener noreferrer" target="_blank"><img className='pod-image' src={spotify} alt ="spotify"></img></a>
                <a href="https://www.breaker.audio/elas-programam" rel="noopener noreferrer" target="_blank"><img className='pod-image' src={breaker} alt ="Breaker"></img></a>
                <a href="https://pca.st/qql6ycuk" rel="noopener noreferrer" target="_blank"><img className='pod-image' src={pocket} alt ="vector"></img></a>

            </div>
        </div>
        <div className="pod-box3">
            <p className='p-box1-tit'>Episódios disponíveis</p>
            <Anchor/>
        </div>
        {/* <Apoie/> */}
        <Parceiras/>
        <Frase/>
    </div>
)
}
export default Podcasts